export const state = [
    {"id": 1, "abbreviation": "AL"},
    {"id": 2, "abbreviation": "AK"},
    {"id": 3, "abbreviation": "AZ"},
    {"id": 4, "abbreviation": "AR"},
    {"id": 5, "abbreviation": "CA"},
    {"id": 6, "abbreviation": "CO"},
    {"id": 7, "abbreviation": "CT"},
    {"id": 8, "abbreviation": "DE"},
    {"id": 9, "abbreviation": "FL"},
    {"id": 10, "abbreviation": "GA"},
    {"id": 11, "abbreviation": "HI"},
    {"id": 12, "abbreviation": "ID"},
    {"id": 13, "abbreviation": "IL"},
    {"id": 14, "abbreviation": "IN"},
    {"id": 15, "abbreviation": "IA"},
    {"id": 16, "abbreviation": "KS"},
    {"id": 17, "abbreviation": "KY"},
    {"id": 18, "abbreviation": "LA"},
    {"id": 19, "abbreviation": "ME"},
    {"id": 20, "abbreviation": "MD"},
    {"id": 21, "abbreviation": "MA"},
    {"id": 22, "abbreviation": "MI"},
    {"id": 23, "abbreviation": "MN"},
    {"id": 24, "abbreviation": "MS"},
    {"id": 25, "abbreviation": "MO"},
    {"id": 26, "abbreviation": "MT"},
    {"id": 27, "abbreviation": "NE"},
    {"id": 28, "abbreviation": "NV"},
    {"id": 29, "abbreviation": "NH"},
    {"id": 30, "abbreviation": "NJ"},
    {"id": 31, "abbreviation": "NM"},
    {"id": 32, "abbreviation": "NY"},
    {"id": 33, "abbreviation": "NC"},
    {"id": 34, "abbreviation": "ND"},
    {"id": 35, "abbreviation": "OH"},
    {"id": 36, "abbreviation": "OK"},
    {"id": 37, "abbreviation": "OR"},
    {"id": 38, "abbreviation": "PA"},
    {"id": 39, "abbreviation": "RI"},
    {"id": 40, "abbreviation": "SC"},
    {"id": 41, "abbreviation": "SD"},
    {"id": 42, "abbreviation": "TN"},
    {"id": 43, "abbreviation": "TX"},
    {"id": 44, "abbreviation": "UT"},
    {"id": 45, "abbreviation": "VT"},
    {"id": 46, "abbreviation": "VA"},
    {"id": 47, "abbreviation": "WA"},
    {"id": 48, "abbreviation": "WV"},
    {"id": 49, "abbreviation": "WI"},
    {"id": 50, "abbreviation": "WY"},
    {"id": 51, "abbreviation": "DC"}
]