import React, {useState, useEffect} from 'react';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import StyledBox from "../../../components/StyledBox";
import {
    DataGridPro,
    GridColumnOrderChangeParams,
    GridToolbarColumnsButton,
    GridToolbarContainer, GridToolbarDensitySelector
} from "@mui/x-data-grid-pro";

import {Route} from "react-router-dom";
import {Snackbar, Switch} from "@mui/material";
import {useDispatch} from "react-redux";
import {AxiosSyncDefaultRequest} from "../../../components/default_request";
import server from "../../../server";
import LinearProgress from "@mui/material/LinearProgress";
import CustomNoRowsOverlay from "../../../components/CustomNoRowsOverlay";
// import ToolBar from "../../../components/ToolBar"

import SearchZone from '../../modal/SearchZone'
import {GridActionsCellItem} from "@mui/x-data-grid";

import {buildUrlWithQueryParams} from "../../../components/buildUrlWithQueryParams";
import Alert from "@mui/material/Alert";
import ReorderIcon from "@mui/icons-material/Reorder";

import ZonePageModal from "./Dialog/ZonePageModal";
import Button from "@mui/material/Button";
import RefreshIcon from "@mui/icons-material/Refresh";
import UploadZone from "../../modal/UploadZone"

const Zone = "Zone";


function ZonePage() {
    const [pageSize, setPageSize] = useState(100);
    const [currentPage, setCurrentPage] = useState(0);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchData, SetSearchData] = useState({service_name: '', shipper_zip: ''});
    const [totalRows, setTotalRows] = useState(0);
    const dispatch = useDispatch();
    const [apiloading, SetApiLoading] = useState(null)
    const [Visibility, setVisibility] = useState({
        id: false, user: false, data: false
    });
    const [checked, setChecked] = React.useState({

    });

    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success', // 可以是 'error', 'warning', 'info', 'success'
    });
    const columns = [
        {id: 1, field: "id", headerName: "ID", flex: 5},
        {
            id: 2, field: "service_name", headerName: "service_name", flex: 3
        },
        {
            id: 3, field: "year", headerName: "year", flex: 5

        },
        {id: 4, field: "quarter", headerName: "quarter", flex: 5},
        {
            id: 5, field: 'actions', type: 'actions', headerName: "操作", renderCell: (params) => {
                return [
                    <span key={params.row.id}>
                        <GridActionsCellItem
                            icon={<ReorderIcon/>}
                            label="分区信息"
                            title={"分区展示"}
                            color="primary"
                            sx={{mr: 1}}
                            onClick={() => {
                                handleOpenModal(params.row.data, params.row.service_name, params.row.year, params.row.quarter)
                            }}
                        />

                    </span>
                ];
            }
        }

    ];
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [d, setD] = useState([])
    const [service_name, setServiceName] = useState(null);
    const [quarter, setQuarter] = useState(null)
    const [year, setYear] = useState(null)
    const handleOpenModal = (data, service_name, year, quarter) => {
        setD(data)
        setServiceName(service_name)
        setYear(year)
        setQuarter(quarter)
        setOpenEditDialog(true);
    };

    const ColumnOrderChange = (e: GridColumnOrderChangeParams) => {
        let old = this.set_columns[e.oldIndex]
        let target = this.set_columns[e.targetIndex]
        this.set_columns[e.targetIndex] = old
        this.set_columns[e.oldIndex] = target
        localStorage.setItem(this.localStorageKey, JSON.stringify(this.set_columns))
    }


    const handleSearch = (e) => {
        setLoading(true)
        fetchData("GET", {}, null)
    }
    const Refresh = (open=true) => {
        // setData([]);
        setData([]);
        setLoading(true);
        fetchData('GET', {}, null, {}, open).then(r => {})

    }
    const handleSave = (editedData, rowId, method='PUT') => {
        // 直接返回 fetchData 函数调用的 Promise
        let result = {status: false, message: ''};
        return fetchData(method, editedData, rowId
        ).then((e) => {
            Refresh()
            result.status = e !== undefined;
            return result;
        }).catch((error) => {
            result.status = false;
            result.message = error.response.message;
            return result;
        }).finally(() => {
            return result;
        });
    }
    const downloads = (filename) => {
        const a = document.createElement('a');
        // 这里添加向后端请求下载文件的逻辑
        if (server.test) {
            a.href = `${server.test_host}:${server.post}${server.download_get_ship_url}?file=${filename}`;
        } else {
            a.href = `${server.host}${server.download_get_ship_url}?file=${filename}`;
        }
        a.target = "_blank";
        document.body.appendChild(a); // 添加到body
        a.click();
        a.remove();
    }
    const fetchData = async (method, data = {}, rowId = null, sort_data, open=true, down_data={}) => {
        try {
            const queryParams = {
                ...searchData, // 将搜索条件作为查询参数
                ...sort_data,
                ...down_data,
                curr_page: currentPage < 1 ? 1 : currentPage,
                page_size: pageSize,
            };

            let requestUrl = server.zone_list;
            let requestData = {};
            let msg = "获取"
            if (method === 'GET') {
                // if(Object.keys(down_data).length !== 0){
                //     requestUrl = server.zone_download
                // }
                requestUrl = buildUrlWithQueryParams(requestUrl, queryParams);

            } else if (method === 'PUT' && rowId != null) {
                // 对于PUT请求，添加row id到URL，并设置编辑的数据作为请求体
                requestUrl = `${requestUrl}${rowId}/`;
                requestData = data;
                msg = "修改"
            }else if (method === "DELETE") {
                requestUrl = `${requestUrl}${rowId}/`;
                msg = "删除"
            }else if (method === "POST") {
                requestData = data;
                msg = "新增"
            }

            return await AxiosSyncDefaultRequest({
                url: requestUrl,
                method: method,
                data: requestData,
                success: (res) => {
                    setTotalRows(1)


                    if (method === 'GET') {

                        if(res.data.status){
                            if(Object.keys(down_data).length === 0) {
                                setData(res.data.rows);
                                setLoading(false);
                                setTotalRows(res.data.total);
                            }
                            else {
                               let filename = res.data.filename
                                downloads(filename)

                            }
                        }
                        else {
                            setSnackbar({ open: true, message: res.data.message, severity: 'error' });
                        }

                    }
                    if(open){
                        setSnackbar({ open: true, message: `${msg}成功！`, severity: 'success' });
                    }


                },
                fail: (error) => {
                    console.error('请求失败', error);
                    setData([]);
                    setLoading(false);
                    const errorMessage = JSON.stringify(error.response.data);
                    setSnackbar({ open: true, message: errorMessage, severity: 'error' });
                    // throw error
                }
            });
        } catch (error) {
            setSnackbar({open: true, message: error.message, severity: 'error'})
            console.error('请求异常', error);
        }
    };

    useEffect(() => {
        setLoading(true)

        // 调用上面定义的函数来加载数据
        fetchData("GET", {}, null, {}, false).then();
    }, [currentPage, pageSize, dispatch]);

    const handleSortModelChange = async (sortModel) => {
        let sort = '';
        if (sortModel.length > 0) {
            // 处理所有的排序模型，虽然通常只有一个
            sort = sortModel.map(model => {
                return model.sort === 'asc' ? model.field : `-${model.field}`;
            }).join(',');
        }
        // 构造排序的数据结构
        const sortData = { sort };
        // 调用 fetchData 函数请求数据
        setLoading(true);
        setData([]);
        await fetchData('GET', {}, null, sortData);
    };
    const onChangeColumnVisibilityModel = (newVisibility) => {
        setVisibility(newVisibility);
    };
    const download = (service_name, year, quarter, file_type) => {
        let download_data = {service_name, year, quarter, file_type}
        fetchData("GET", {}, null, {}, true, download_data)
    }

    const ToolBar = ({ onRefresh }) => {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                {/*<GridToolbarFilterButton/>*/}
                <GridToolbarDensitySelector />
                {/*<GridToolbarExport/>*/}
                <Button startIcon={<RefreshIcon />} onClick={onRefresh}>
                    刷新
                </Button>
                <UploadZone setSnackbar={setSnackbar} SetApiLoading={SetApiLoading} onRefresh={() => onRefresh(false)}></UploadZone>
                {/* 动态渲染额外的选项或按钮 */}
            </GridToolbarContainer>
        );
    };
    return (

        <Box sx={{
            width: '100%'
        }}>
            <SearchZone handleSearch={handleSearch} searchData={searchData} SetSearchData={SetSearchData}></SearchZone>
            <Grid container>
                <Grid item md={12} lg={12}>
                    <StyledBox>
                        {data ? <DataGridPro
                            rows={data}
                            density="compact"
                            columns={columns}
                            pageSize={pageSize}
                            columnVisibilityModel={Visibility}
                            rowsPerPageOptions={[25, 30, 50, 100, 200, 500]}
                            checkboxSelection={false}
                            headerHeight={70}
                            loading={loading}
                            rowCount={data.length}
                            paginationMode='server'
                            onPageChange={(page) => setCurrentPage(page)}
                            onPageSizeChange={pageSize => setPageSize(pageSize)}
                            rowReordering={false}
                            pagination={true}
                            sortingMode="server"
                            components={{
                                LoadingOverlay: LinearProgress,
                                NoRowsOverlay: CustomNoRowsOverlay,
                                Toolbar: () => <ToolBar onRefresh={Refresh} />,
                            }}
                            onColumnVisibilityModelChange={onChangeColumnVisibilityModel}
                            onSortModelChange={handleSortModelChange}
                            columnBuffer={3}
                            columnThreshold={3}/> : ''}
                    </StyledBox>
                </Grid>
            </Grid>

            <ZonePageModal open={openEditDialog} onClose={() => setOpenEditDialog(false)}
                           setSnackbar={setSnackbar} SetApiLoading={SetApiLoading}  Refresh={() => Refresh(false)}
                             service_name={service_name} year={year} quarter={quarter} rowData={d}
                           download={download}
            ></ZonePageModal>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={2000}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
            {apiloading}
        </Box>
    )
}

export default Zone;
export {ZonePage};