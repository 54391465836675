import React, {useState, useEffect} from 'react';
import { useSelector } from 'react-redux';

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import StyledBox from "../../components/StyledBox";
import {
    DataGridPro,
} from "@mui/x-data-grid-pro";
import LinearProgress from "@mui/material/LinearProgress";
import CustomNoRowsOverlay from "../../components/CustomNoRowsOverlay";
import {buildUrlWithQueryParams} from "../../components/buildUrlWithQueryParams";
import server from "../../server";
import {AxiosSyncDefaultRequest} from "../../components/default_request";
import ToolBar from "../../components/ToolBar";
import Alert from "@mui/material/Alert";
import {FormControl, Snackbar, TextField} from "@mui/material";
import {GridActionsCellItem} from "@mui/x-data-grid";
import CloseIcon from '@mui/icons-material/Close';
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import Dialog from "@mui/material/Dialog";
const status_chinese = {
    "0": "未知", "1": "完成", "2": "取消"
}
const Pickup = "Pickup";
function PickupPage() {
    const [pageSize, setPageSize] = useState(100);
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState(false);
    const [cancelId, setCancelId] = useState(null);
    const [cancelOpenDialog, setCancelOpenDialog] = useState(false);
    const [cancelLoading, setCancelLoading] = useState(false);
    const [reason, setReason] = useState("");
    const user = useSelector((state) => state.auth.user);
    const [columnWidths, setColumnWidths] = React.useState({});
    const [searchData, setSearchData] = useState({
        pickup_code: "", RecipientName: "", Address1: "", Address2: "",
        PhoneNumber: "", City: "", PostalCode: "",
        master_tracking_numbers: "",
    });
    const [statusFormData, setStatusFormData] = useState({
        reason: {required: true, message: "", status: true},
    });
    const [Visibility, setVisibility] = useState({
        id: false,
        Check_amount: parseInt(user.is_open_claim) === 4
    })
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success', // 可以是 'error', 'warning', 'info', 'success'
    });

    const handleColumnResize = (params) => {
        setColumnWidths((prevWidths) => ({
            ...prevWidths,
            [params.colDef.field]: params.width,
        }));
    };
    const CancelHandler = () => {
        const newErrors = {};
        let is_valid = true;
        // 检查所有必填字段是否已填写
        for (let field in statusFormData) {
            if (statusFormData[field].required && !reason) {
                newErrors[field] = {
                    ...statusFormData[field],
                    message: 'This field is required',
                    status: false
                };
                is_valid = false;
            }else{
                newErrors[field] = {
                    ...statusFormData[field],
                    message: '',
                    status: true
                };
            }
        }
        // 如果有错误，更新状态并停止保存操作
        setStatusFormData({
            ...statusFormData,
            ...newErrors
        });
        if (!is_valid) {
            return;
        }
        setStatusFormData({
            ...statusFormData,
            newErrors
        })
        setCancelLoading(true);
        fetchData(
            'DELETE',
            {reason: reason, pickupCode: cancelId}, null,
            {"WEB-USER": user.username}).then((r) => {
                if (r.data.status) {
                    setSnackbar({ open: true, message: r.data.message, severity: 'success' });
                    Refresh()
                }else {
                    setSnackbar({ open: true, message: r.data.message, severity: 'error' });
                }
                setCancelLoading(false);
                setCancelOpenDialog(false);
        });
    }
    const columns = [
        {id: 1, field: "id", headerName: "ID"},
        // {id: 2, field: "UID", headerName: "UID", width: columnWidths['UID'] || 100},
        {id: 3, field: "pickup_code", headerName: "预约码", width: columnWidths['pickup_code'] || 180},
        {
            id: 4,
            field: "status",
            headerName: "预约状态",
            width: columnWidths['status'] || 150, renderCell: (params) => {
                // let status = status_dict[params.row.status]
                let chinese_status = status_chinese[params.row.status]
                return (
                    <span>
                        {chinese_status}
                    </span>
                )
        }},
        {id: 5, field: "RecipientName", headerName: "收件人", width: columnWidths['RecipientName'] || 120},
        {id: 6, field: "Address1", headerName: "地址1", width: columnWidths['Address1'] || 120},
        {id: 7, field: "Address2", headerName: "地址2", width: columnWidths['Address2'] || 200},
        {id: 8, field: "PhoneNumber", headerName: "联系方式", width: columnWidths['PhoneNumber'] || 150},
        {id: 9, field: "City", headerName: "城市", width: columnWidths['City'] || 120},
        {id: 10, field: "PostalCode", headerName: "邮编", width: columnWidths['PostalCode'] || 100},
        {
            id: 11, field: "master_tracking_numbers", headerName: "预约运单主单号",
            width: columnWidths['master_tracking_numbers'] || 300,
            renderCell: (params) => {
                let mtn = "";
                const value = eval(params.value);
                for (let i=0;i < value.length;i++){
                    mtn = mtn + value[i] + ","
                }
                return mtn.slice(0,-1)
            }
        },
        {id: 12, type: 'actions', field: 'actions', headerName: "Actions", width:100, getActions: (params) => {
                return [
                    <GridActionsCellItem
                        icon={<CloseIcon />}
                        label="取消"
                        title={"取消"}
                        onClick={() => {
                            setCancelOpenDialog(true);
                            setCancelId(params.row.pickup_code);
                        }}
                        key={`cancel-${params.id}`}
                        color="primary"
                    />,
                ];
            }}

        // next 16
    ];
    const fetchData = async (method, data = {}, rowId = null, headers = {}) => {
        try {
            const queryParams = {
                ...searchData, // 将搜索条件作为查询参数
                curr_page: currentPage < 1 ? 1 : currentPage,
                page_size: pageSize,
            };

            let requestUrl = server.pickup_url;
            let requestData = {};

            if (method === 'GET') {
                requestUrl = buildUrlWithQueryParams(requestUrl, queryParams);
            } else if (method === 'PUT' && rowId != null) {
                // 对于PUT请求，添加row id到URL，并设置编辑的数据作为请求体
                requestUrl = `${requestUrl}${rowId}/`;
                requestData = data;
            } else if (method === "DELETE") {
                requestData = data;
            } else if (method === "POST") {
                requestData = data;
            } else if (method === "PATCH") {
                requestUrl = `${requestUrl}batch_update/`;
                requestData = data;
            }
            console.log(requestData);
            // 直接返回AxiosSyncDefaultRequest调用
            return AxiosSyncDefaultRequest({
                url: requestUrl,
                method: method,
                data: requestData,
                headers: headers,
                success: (res) => {
                    if (method === 'GET') {
                        setData(res.data.rows);
                        setLoading(false);
                        setTotalRows(res.data.total);
                    } else {
                        return res;
                    }
                },
                fail: (error) => {
                    console.error('请求失败', error);
                    setData([]);
                    setLoading(false);
                    const errorMessage = JSON.stringify(error.response.data);
                    setSnackbar({ open: true, message: errorMessage, severity: 'error' });
                    throw error;
                }
            });

        } catch (error) {
            console.error('请求异常', error);
            throw error;
        }
    }

    useEffect(() => {
        setLoading(true);
        // 调用上面定义的函数来加载数据
        fetchData('GET', {}, null, {"WEB-USER": user.username}).then(r => {});
    }, [currentPage, pageSize]);
    const Refresh = () => {
        setData([]);
        setLoading(true);
        fetchData('GET', {}, null, {"WEB-USER": user.username}).then(r => {})
        setSearch(false);
    };
    const onChangeColumnVisibilityModel = (newVisibility) => {

        setVisibility(newVisibility);
    }
    return (
        <Box sx={{
            width: '100%'
        }}>
            <Grid container>
                {/*<Grid container item md={12} lg={12}>*/}
                    {/*<Box sx={{minWidth: '100%'}}>*/}
                    {/*</Box>*/}
                {/*</Grid>*/}
                <Grid item md={12} lg={12}>
                    <StyledBox>
                        {data ? <DataGridPro
                            editMode={"cell"}
                            rows={data}
                            density="compact"
                            columns={columns}
                            pageSize={pageSize}
                            columnVisibilityModel={Visibility}
                            rowsPerPageOptions={[25, 30, 50, 100, 200, 500]}
                            // checkboxSelection
                            headerHeight={70}
                            loading={loading}
                            rowCount={totalRows}
                            paginationMode='server'
                            onPageChange={(page) => setCurrentPage(page)}
                            onPageSizeChange={pageSize => setPageSize(pageSize)}
                            rowReordering={false}
                            pagination={true}
                            sortingMode="server"
                            onColumnVisibilityModelChange={onChangeColumnVisibilityModel}
                            components={{
                                LoadingOverlay: LinearProgress,
                                NoRowsOverlay: CustomNoRowsOverlay,
                                Toolbar: () => <ToolBar onRefresh={Refresh} />,
                            }}
                            // onSelectionModelChange={(e) => {
                            //     setSelectedRow(e)
                            // }}
                            onColumnResize={handleColumnResize}
                            initialState={
                                {pinnedColumns: {right: ['actions']}}
                            }
                            columnBuffer={3}
                            columnThreshold={3}/> : ''}
                    </StyledBox>
                </Grid>
            </Grid>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
            <Dialog open={cancelOpenDialog} onClose={() => setCancelOpenDialog(false)}>
                <DialogTitle>取消预约</DialogTitle>
                <DialogContent>
                    <FormControl sx={{m: 1, minWidth: 500}}>
                        <TextField
                            id="Reason"
                            name="Reason"
                            type="text"
                            value={reason}
                            onChange={(e) => setReason(e.target.value)}
                            label="Reason"
                            variant="standard"
                            required
                            error={!statusFormData.reason.status}
                            helperText={statusFormData.reason.message}
                        />
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setCancelOpenDialog(false)}>否</Button>
                    <LoadingButton
                        loading={cancelLoading}
                        variant={"contained"}
                        onClick={CancelHandler}
                    >是</LoadingButton>
                </DialogActions>
            </Dialog>
        </Box>
    )
}

export default Pickup;
export {PickupPage};