import React, {useState, useEffect} from 'react';
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {InternalClaimBar} from "./claimSearchBar/InternalClaimBar";
import StyledBox from "../../components/StyledBox";
import {
    DataGridPro,
} from "@mui/x-data-grid-pro";
import LinearProgress from "@mui/material/LinearProgress";
import CustomNoRowsOverlay from "../../components/CustomNoRowsOverlay";
import {buildUrlWithQueryParams} from "../../components/buildUrlWithQueryParams";
import server from "../../server";
import {AxiosSyncDefaultRequest} from "../../components/default_request";
import ToolBar from "../../components/ToolBar";
import Alert from "@mui/material/Alert";
import {Snackbar} from "@mui/material";
import UpdateClaimA from "./Dialog/UpdateClaimA";
import VerifyClaimA from "./Dialog/VerifyClaimA";
import RevokeClaimA from "./Dialog/RevokeClaimA";
import ConfirmRefundClaimA from "./Dialog/ConfirmRefundClaimA";
import UploadClaimA from "./Dialog/UploadClaimA";

const InternalClaims = "InternalClaims";

const status_chinese = {
    "n": "未确认",
    "y": "已确认"
}
const returned_chinese = {
    "f": "未退款",
    "t": "已退款"
}
function getUtcOffsetDetailed() {
    const offsetMinutes = new Date().getTimezoneOffset();
    const hours = Math.floor(Math.abs(offsetMinutes) / 60);
    const minutes = Math.abs(offsetMinutes) % 60;

    const sign = offsetMinutes > 0 ? '-' : '+';

    return `${sign}${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
}
function verify_format(params) {
    if (params.value === "2000-01-01 00:00:00"){
        return ``
    }else{
        const utcDate = new Date(params.value + "Z")
        return `${utcDate.toLocaleString()} ${getUtcOffsetDetailed()}`
    }
}
function create_format(params) {
    const utcDate = new Date(params.value + "Z")
    return `${utcDate.toLocaleString()} ${getUtcOffsetDetailed()}`

}
function InternalClaimPage() {
    const [pageSize, setPageSize] = useState(100);
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [selectedRows, setSelectedRows] = useState([]);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState(false);
    const [searchData, setSearchData] = useState({
        Case_number: "", Tracking_number: "", EFT: "", UID: "",
        invoice_number: "",
        verify_time_after: null, create_time_after: null,
        verify_time_before: null, create_time_before: null,
        status: "", returned: "",
    });
    const [Visibility, setVisibility] = useState({
        id: false,
    })
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success', // 可以是 'error', 'warning', 'info', 'success'
    });
    const [columnWidths, setColumnWidths] = React.useState({

    });

    const handleColumnResize = (params) => {
        console.log(params.colDef.field)
        setColumnWidths((prevWidths) => ({
            ...prevWidths,
            [params.colDef.field]: params.width,
        }));
    };
    const columns = [
        {id: 1, field: "id", headerName: "ID", width: columnWidths['ID'] || 100},
        {id: 2, field: "UID", headerName: "UID", sortable: true, width: columnWidths['UID'] || 100},
        {id: 3, field: "create_time", headerName: "上传时间", valueFormatter: create_format, sortable: true, width: columnWidths["create_time"] || 210},
        {id: 4, field: "verify_time", headerName: "确认时间", valueFormatter: verify_format, sortable: true, width: columnWidths['verify_time'] || 210},
        {id: 5, field: "Case_number", headerName: "Case号码", sortable: true, width: columnWidths['Case_number'] || 110},
        {id: 6, field: "Tracking_number", headerName: "追踪号", sortable: true, width: columnWidths['Tracking_number'] || 180},
        {id: 7, field: "EFT", headerName: "EFT", sortable: true, width: columnWidths['EFT'] || 180},
        {id: 8, field: "invoice_number", headerName: "账单号", sortable: true, width: columnWidths['invoice_number'] || 220},
        {id: 9, field: "Claim_amount", headerName: "索赔金额", sortable: true, width: columnWidths['Claim_amount'] || 100},
        {id: 10, field: "Check_amount", headerName: "确认金额", sortable: true, width: columnWidths['Check_amount'] || 120},
        {
            id: 11, field: "status", headerName: "状态", resizable: false,  width: 80, renderCell: (params) => {
                let my_status;
                if (params.row.status === "0"){
                    my_status = 'n'
                }else if (params.row.status === "1"){
                    my_status = 'y'
                }
                else{
                    my_status = 'c'
                }
                let chinese_status = status_chinese[my_status]
                return (
                    <div className={`ClaimCellWithStatus ${my_status}`}>
                        {chinese_status}
                    </div>
                )
            }
        },
        {
            id: 12, field: "returned", headerName: "退款状态", resizable: false, width: 80, renderCell: (params) => {
                let returned;
                if (params.row.returned){
                    returned = 't'
                }else{
                    returned = 'f'
                }
                let chinese_returned = returned_chinese[returned]
                return (
                    <div className={`ClaimCellWithStatus ${returned}`}>
                        {chinese_returned}
                    </div>
                )
            }
        },
        // next 13
    ];
    const uploadClaim = async (formData) => {
        try {
            const response = await AxiosSyncDefaultRequest({
                url: server.internal_claims_list, // 确保requestUrl是正确的请求地址
                method: 'POST',
                data: formData, // 使用FormData作为请求体
                headers: {
                    'Content-Type': 'multipart/form-data', // 重要：设置请求头以正确传递FormData
                },
                success: (res) => {
                    // 处理成功的响应
                    console.log(res)  // TODO
                },
                fail: (error) => {
                    // 处理失败的响应
                    console.error('请求失败', error);
                    setData([]);
                    setLoading(false);
                    const errorMessage = JSON.stringify(error.response.data);
                    setSnackbar({ open: true, message: errorMessage, severity: 'error' });
                    // 这里可能不需要throw error，因为你已经在catch块中处理了异常
                }
            });
            return response; // 返回响应数据
        } catch (error) {
            console.error('请求异常', error);
            // 在这里处理异常
        }
    };
    const handleSortModelChange = async (sortModel) => {
        let ordering = '';
        if (sortModel.length > 0) {
            // 处理所有的排序模型，虽然通常只有一个
            ordering = sortModel.map(model => {
                return model.sort === 'asc' ? model.field : `-${model.field}`;
            }).join(',');
        }
        // 构造排序的数据结构
        const sortData = { ordering };
        // 调用 fetchData 函数请求数据
        setLoading(true);
        setData([]);
        await fetchData('GET', {}, null, sortData);
    };

    const fetchData = async (method, data = {}, rowId = null, sortData={}) => {
        try {
            const search_data = JSON.parse(JSON.stringify(searchData));
            // console.log()
            if(search_data["verify_time_after"]){
                search_data["verify_time_after"] = new Date(search_data["verify_time_after"]).toLocaleDateString("en")
            }
            if(search_data["verify_time_before"]){
                search_data["verify_time_before"] = new Date(search_data["verify_time_before"]).toLocaleDateString("en")
            }
            if(search_data["create_time_after"]){
                search_data["create_time_after"] = new Date(search_data["create_time_after"]).toLocaleDateString("en")
            }
            if(search_data["create_time_before"]){
                search_data["create_time_before"] = new Date(search_data["create_time_before"]).toLocaleDateString("en")
            }
            const queryParams = {
                ...search_data, // 将搜索条件作为查询参数
                ...sortData,  // 将排序数据合并进来
                curr_page: currentPage < 1 ? 1 : currentPage,
                page_size: pageSize,
            };

            let requestUrl = server.internal_claims_list;
            let requestData = {};

            if (method === 'GET') {
                requestUrl = buildUrlWithQueryParams(requestUrl, queryParams);
            } else if (method === 'PUT' && rowId != null) {
                // 对于PUT请求，添加row id到URL，并设置编辑的数据作为请求体
                requestUrl = `${requestUrl}${rowId}/`;
                requestData = data;
            }else if (method === "DELETE") {
                requestUrl = `${requestUrl}${rowId}/`;
            }else if (method === "POST") {
                requestData = data;
            }

            return await AxiosSyncDefaultRequest({
                url: requestUrl,
                method: method,
                data: requestData,
                success: (res) => {
                    if (method === 'GET') {
                        if (res.status === 204){
                            console.log(res)
                            setSearchData({...searchData, submitType: ""})
                            setLoading(false);
                            console.log(data);
                            setSnackbar({ open: true, message: "Export process started", severity: 'success' });
                        }else {
                            setData(res.data.rows);
                            setLoading(false);
                            setTotalRows(res.data.total);
                        }
                    }else{
                        return res
                    }
                },
                fail: (error) => {
                    console.error('请求失败', error);
                    setData([]);
                    setLoading(false);
                    const errorMessage = JSON.stringify(error.response.data);
                    setSnackbar({ open: true, message: errorMessage, severity: 'error' });
                    // throw error
                }
            });
        } catch (error) {
            console.error('请求异常', error);
        }
    };

    useEffect(() => {
        setLoading(true);
        // 调用上面定义的函数来加载数据
        fetchData('GET').then(r => {});
    }, [currentPage, pageSize]);

    const triggerSearch = () => {
        if (searchData.submitType !== "export"){
            setLoading(true);
            setData([]);
        }
        setSearch(true);
        fetchData('GET').then(r => {})
        setSearch(false);
    };

    const Refresh = () => {
        setData([]);
        setLoading(true);
        fetchData('GET').then(r => {})
        setSearch(false);
    }
    const onChangeColumnVisibilityModel = (newVisibility) => {
        // if (user.is_open_claim !== "4") {
        //     newVisibility = { ...newVisibility, Check_amount: false };
        // }
        setVisibility(newVisibility);
    }

    return (
        <Box sx={{
            width: '100%'
        }}>
            <Grid container>
                <Grid container item md={12} lg={12}>
                    <Box sx={{minWidth: '100%'}}>
                        <InternalClaimBar
                            searchData={searchData}
                            setSearchData={setSearchData}
                            search={search}
                            setSearch={triggerSearch}
                            setCurrentPage={setCurrentPage}
                        />
                        <UploadClaimA
                            uploadClaim={uploadClaim}
                            Refresh={Refresh}
                        />
                        <UpdateClaimA
                            data={data}
                            claim_update_list={selectedRows}
                            updateClaim={fetchData}
                            Refresh={Refresh}
                        />
                        <VerifyClaimA
                            data={data}
                            claim_update_list={selectedRows}
                            verifyClaim={fetchData}
                            Refresh={Refresh}
                        />
                        <RevokeClaimA
                            data={data}
                            claim_update_list={selectedRows}
                            revokeClaim={fetchData}
                            Refresh={Refresh}
                        />
                        <ConfirmRefundClaimA
                            data={data}
                            claim_update_list={selectedRows}
                            confirmRefundClaim={fetchData}
                            Refresh={Refresh}
                        />
                    </Box>
                </Grid>
                <Grid item md={12} lg={12}>
                    <StyledBox>
                        {data ? <DataGridPro
                            rows={data}
                            density="compact"
                            columns={columns}
                            pageSize={pageSize}
                            columnVisibilityModel={Visibility}
                            rowsPerPageOptions={[25, 30, 50, 100, 200, 500]}
                            checkboxSelection
                            headerHeight={70}
                            loading={loading}
                            rowCount={totalRows}
                            paginationMode='server'
                            onPageChange={(page) => setCurrentPage(page)}
                            onPageSizeChange={pageSize => setPageSize(pageSize)}
                            rowReordering={false}
                            pagination={true}
                            sortingMode="server"
                            onColumnVisibilityModelChange={onChangeColumnVisibilityModel}
                            components={{
                                LoadingOverlay: LinearProgress,
                                NoRowsOverlay: CustomNoRowsOverlay,
                                Toolbar: () => <ToolBar onRefresh={Refresh} />,
                            }}
                            onSortModelChange={handleSortModelChange}
                            onSelectionModelChange={(e) => {
                                setSelectedRows(e)
                            }}
                            onColumnResize={handleColumnResize}
                            initialState={
                                {pinnedColumns: {right: ['status', 'returned']}}
                            }
                            columnBuffer={3}
                            columnThreshold={3}/> : ''}
                    </StyledBox>
                </Grid>
            </Grid>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    )
}

export default InternalClaims;
export {InternalClaimPage};