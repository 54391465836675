import {
    USER_LOAD,
    USER_ERR,
    USER_LOADING,
    LOGOUT_SUCCESS,
    REGISTER_SUCCESS,
    REGISTER_LOADING,
    AUTH_ERROR,
    LOGIN_SUCCESS,
    LOGIN_LOADING,
    SET,
    LOGIN_ERR,
    CHANGE_PASSWORD_LOADING, CHANGE_PASSWORD_SUCCESS, REGISTER_FAIL
} from "../type";
import {AxiosDefaultRequest, AxiosSyncDefaultRequest} from "../../components/default_request";
import store from "../store";


export const loadUser = () => (dispatch, getState) => {
    dispatch({type: USER_LOADING});
    AxiosDefaultRequest({
        url: '/login/',
        method: 'GET',
        withCredentials: true, // 确保cookie随请求发送
        success: res => {
            dispatch({type: LOGIN_SUCCESS, payload: res.data});
        },
        err: err => {
            // 如果返回401，认为用户未授权或会话失效
            if (err.response && err.response.status === 401) {
                dispatch({
                    type: LOGIN_ERR, payload: {
                        level: "info", title: "Authentication Error", message: "Please log in again.", timeout: 3000
                    }
                });
            } else {
                // 其他错误，如网络问题或服务器错误
                dispatch({
                    type: LOGIN_ERR, payload: {
                        level: "error", title: "Error", message: "An error occurred. Please try again later.", timeout: 3000
                    }
                });
            }
            // 会话认证无需在本地存储中保存令牌
        }
    });
};



export const login = (username, password, RememberMe) => async dispatch => {
    dispatch({ type: LOGIN_LOADING });

    try {
        const response = await AxiosSyncDefaultRequest({
            method: "POST",
            url: "/login/",
            data: JSON.stringify({
                "username": username,
                "password": password
            }),
            withCredentials: true // 确保cookie包含在请求中
        });

        let result = response.data;
        result['title'] = 'SUCCESS';
        result['level'] = 'success';
        result['timeout'] = 3000;
        result['message'] = result['message'] + ' 您将在3秒后跳转到首页！';

        dispatch({ type: SET, payload: result });

        if (RememberMe) {
            localStorage.setItem('username', username);
            localStorage.setItem('password', password);
            localStorage.setItem('RememberMe', RememberMe);
        }

        // 登录成功后的操作
        dispatch({ type: LOGIN_SUCCESS, payload: result });

        // 如果需要延迟跳转，可以使用Promise
        await new Promise(resolve => setTimeout(resolve, 3000));
        // 执行跳转或其他逻辑

    } catch (error) {
        // 如果请求失败，处理错误
        const result = {
            'title': 'FAILED',
            'level': 'error',
            'timeout': 5000,
            'message': '认证错误，请检查输入的用户名和密码后再次尝试登录！'
        };

        dispatch({ type: LOGIN_ERR, payload: result });
    }
};




export const Logout = () => (dispatch, getState) => {
    dispatch({type: USER_LOADING});
    AxiosDefaultRequest({
        method: "GET",
        url: "/logout/",
        success: (msg) => {
            setTimeout(() => {
                dispatch({
                    type: LOGOUT_SUCCESS
                });
            }, 500)

        },
        error: (msg) => {
            dispatch({
                type: AUTH_ERROR
            })
        }
    });
}

export const changePassword = (user, oldPassword, newPassword) => (dispatch, getState) => {
    dispatch({type: CHANGE_PASSWORD_LOADING});

    AxiosDefaultRequest({
        method: "POST",
        url: "/change_password/",
        data: JSON.stringify({
            'username': user.username,
            'oldPassword': oldPassword,
            'newPassword': newPassword
        }),
        success: (msg) => {
            let result = msg.data
            if (result.status){
                result['title'] = 'SUCCESS'
                result['level'] = 'success'
                result['message'] = result['message']
                dispatch({type: SET, payload: result})
                setTimeout(() => {
                    dispatch({
                        type: CHANGE_PASSWORD_SUCCESS
                    });
                }, 100)
            }else {
                result['title'] = 'FAILED'
                result['level'] = 'error'
                result['timeout'] = 10000
                result['message'] = result['message']
                dispatch({type: SET, payload: result})
            }
        },
        error: (msg) => {
            dispatch({
                type: AUTH_ERROR
            })
        }
    });
}

export const register = (username, password, uid, key, src_user, pfn, services) => dispatch => {
    dispatch({type: REGISTER_LOADING})
    AxiosDefaultRequest({
        method: "POST",
        url: "/register/",
        headers: {'WEB-USER': store.getState().auth.user.username},
        data: {
            "username": username,
            // "password": password,
            "UID": uid,
            "APIkey": key,
            "src_user": src_user,
            "Pfn": pfn,
            "services": services
        },
        success: (msg) => {
            let result = msg.data
            result['title'] = 'SUCCESS'
            result['level'] = 'success'
            result['token'] = result['token']
            result['timeout'] = 3000
            result['message'] = result['message']
            dispatch({type: REGISTER_SUCCESS, payload: result})

        },
        err: (e, f, g) => {
            let message
            if("response" in e && "data" in e.response && "message" in e.response.data) {
                message = e.response.data.message
            }
            else {
                message = 'Registration failed, please check the data and submit again!'
            }
            const result = {'title': 'FAILED', 'level': 'error', 'timeout': 10000,
                'message': message}
            setTimeout(() => {
                dispatch({type: REGISTER_FAIL, payload: result})
            }, 3000)
        }
    });
};