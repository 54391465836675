import React, {useState, useEffect} from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    TextField,
    Box,
    Snackbar, Input
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Alert from "@mui/material/Alert";
import AdsClickIcon from '@mui/icons-material/AdsClick';
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import InputFileUpload from "../../../components/renderField";
import LoadingButton from "@mui/lab/LoadingButton";
import MultiFileUpload from "../../../components/MultiFileUpload";
import FormHelperText from "@mui/material/FormHelperText";

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

function CreateCustomerClaim({uploadClaim, Refresh, user, }) {
    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState({
        UID: "", supplier: "",
        claim_type: "", Tracking_number: "",  shipping_charges: "",  unit_price: "",
        Qty: "",  currency_type: "U.S.Dollar(UCD)",  commodity_type: "",  item_description: "",  Additional_Comments: "",
        OrderNumber: "",  ShipDate: "",  TotalClaimAmount: "",  claim_for: "",
    });
    const [loading, setLoading] = useState(false);
    const [files, setFiles] = useState([]);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success', // 可以是 'error', 'warning', 'info', 'success'
    });

    useEffect(() => {
        setFormData(formData => ({ ...formData, UID: user.UID ? user.UID : "" }));
    }, [user.UID]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        if (name === 'supplier') {
            if (value === "SMP"){
                setFormData({
                    ...formData,
                    UID: formData.UID, supplier: value,
                    claim_type: "",  shipping_charges: "", unit_price: "",
                    Qty: "", currency_type: "U.S.Dollar(UCD)", commodity_type: "",
                    item_description: "", note: "",
                })
            }else {
                setFormData({
                    ...formData,
                    UID: formData.UID, supplier: value,
                    OrderNumber: "",  ShipDate: "",
                    unit_price: "", shipping_charges: "",
                    TotalClaimAmount: "", claim_for: "",
                })
            }
        }else{
            setFormData({
                ...formData,
                [name]: value,
            });
        }

    };
    const openDialog = () => {
        setOpen(true);
    };

    const handleClose = () => setOpen(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        // 创建 FormData 对象
        const data = new FormData();

        // 添加文件到 FormData 对象，如果有多个文件，需要为每个文件调用 append
        for (let i = 0; i < files.length; i++) {
            data.append('upload_claim_file', files[i]);
        }

        // 将其他表单数据添加到 FormData 对象
        for (const [key, value] of Object.entries(formData)) {
            // 避免将文件数组添加到 FormData 中
            if (key !== 'upload_claim_file') {
                if(key === "shipping_charges" && !value){
                    data.append(key, 0)
                }
                else {
                    data.append(key, value);
                }

            }
        }
        try {
            const result = await uploadClaim(data);
            if (result?.status === 200) {
                setSnackbar({open: true, message: '索赔信息创建成功!', severity: 'success'});
                handleClose();
            }else {
                setSnackbar({open: false, message: '索赔信息创建失败!', severity: 'error'});
                handleClose();
            }
        } catch (e) {
            console.error("索赔信息创建失败!", e);
        } finally {
            setLoading(false);
            Refresh()

        }
    };
    const renderFormFields = () => {
        if (formData.supplier !== "SMP") {
            return (
                <>
                    <FormControl variant="standard" sx={{m: 1, minWidth: 500}}>
                        <InputLabel id="LabelClaimType">Claim Type</InputLabel>
                        <Select
                            labelId="LabelClaimType"
                            id="claim_type"
                            name="claim_type"
                            value={formData.claim_type}
                            onChange={handleChange}
                            label="claim_type"
                            required
                        >
                            <MenuItem value={"Misdelivery"} selected>Misdelivery</MenuItem>
                            <MenuItem value={"Shipment Not Received"}>Shipment Not Received</MenuItem>
                            <MenuItem value={"Shipment Missing Contents"}>Shipment Missing Contents</MenuItem>
                            <MenuItem value={"Shipment Damaged"}>Shipment Damaged</MenuItem>
                            <MenuItem value={"COD Payment Not Collected"}>COD Payment Not Collected</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl sx={{m: 1, minWidth: 500}}>
                        <TextField
                            id="Tracking_number"
                            name="Tracking_number"
                            type="text"
                            value={formData.Tracking_number}
                            onChange={handleChange}
                            label="Tracking Number"
                            variant="standard"
                            required
                        />
                    </FormControl>
                    <FormControl sx={{m: 1, minWidth: 500}}>
                        <TextField
                            id="shipping_charges"
                            name="shipping_charges"
                            type="number"
                            value={formData.shipping_charges}
                            onChange={handleChange}
                            label="Shipping charges"
                            variant="standard"
                        />
                    </FormControl>
                    <FormControl sx={{m: 1, minWidth: 500}}>
                        <TextField
                            id="unit_price"
                            name="unit_price"
                            type="number"
                            value={formData.unit_price}
                            onChange={handleChange}
                            label="Unit Price"
                            variant="standard"
                            required
                        />
                    </FormControl>
                    <FormControl sx={{m: 1, minWidth: 500}}>
                        <TextField
                            id="Qty"
                            name="Qty"
                            type="text"
                            value={formData.Qty}
                            onChange={handleChange}
                            label="Qty"
                            variant="standard"
                            required
                        />
                    </FormControl>
                    <FormControl variant="standard" sx={{m: 1, minWidth: 500}}>
                        <InputLabel id="LabelCommodityType">Commodity Type</InputLabel>
                        <Select
                            labelId="LabelCommodityType"
                            id="commodity_type"
                            name="commodity_type"
                            value={formData.commodity_type}
                            onChange={handleChange}
                            label="Commodity type"
                            required
                        >
                            <MenuItem value={"Mis-delivery-Delivery to incorrect address"}>Mis-delivery-Delivery to incorrect address</MenuItem>
                            <MenuItem value={"Antiques, Collections, Jewelry"}>Antiques, Collections, Jewelry</MenuItem>
                            <MenuItem value={"Cell Phones, Tablets, Handheld Devices"}>Cell Phones, Tablets, Handheld Devices</MenuItem>
                            <MenuItem value={"Computer Equipment, Software, Components, Accessories"}>Computer Equipment, Software, Components, Accessories</MenuItem>
                            <MenuItem value={"Documents- Legan, Personal, Business"}>Documents- Legan, Personal, Business</MenuItem>
                            <MenuItem value={"Food, Animal, Agricultural, and Medical products, both perishable and non-perishable"}>Food, Animal, Agricultural, and Medical products, both perishable and non-perishable</MenuItem>
                            <MenuItem value={"Fragile & Glass Items"}>Fragile & Glass Items</MenuItem>
                            <MenuItem value={"Furs, Fine Art, Paintings, Tapestry, Etched Glass"}>Furs, Fine Art, Paintings, Tapestry, Etched Glass</MenuItem>
                            <MenuItem value={"Liquid Chemical or Consumables"}>Liquid Chemical or Consumables</MenuItem>
                            <MenuItem value={"Medicine, Drugs, Controlled Substances"}>Medicine, Drugs, Controlled Substances</MenuItem>
                            <MenuItem value={"Money, Currency, Tickets, bonds, or Equivalents"}>Money, Currency, Tickets, bonds, or Equivalents</MenuItem>
                            <MenuItem value={"Tobacco, Firearms"}>Tobacco, Firearms</MenuItem>
                            <MenuItem value={"None of the Aboves"}>None of the Aboves</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl sx={{m: 1, minWidth: 500}}>
                        <TextField
                            id="item_description"
                            name="item_description"
                            type="text"
                            value={formData.item_description}
                            onChange={handleChange}
                            label="Item Descriptions"
                            variant="standard"
                            required
                        />
                    </FormControl>
                    <FormControl sx={{m: 1, minWidth: 500}}>
                        <TextField
                            id="Additional_Comments"
                            name="Additional_Comments"
                            type="text"
                            value={formData.Additional_Comments}
                            onChange={handleChange}
                            label="Additional Comments"
                            variant="standard"
                        />
                    </FormControl>
                </>
            );
        }else {
            return (
                <>
                    <FormControl sx={{m: 1, minWidth: 500}}>
                        <TextField
                            id="Tracking_number"
                            name="Tracking_number"
                            type="text"
                            value={formData.Tracking_number}
                            onChange={handleChange}
                            label="Tracking Number"
                            variant="standard"
                            required
                        />
                    </FormControl>
                    <FormControl sx={{m: 1, minWidth: 500}}>
                        <TextField
                            id="OrderNumber"
                            name="OrderNumber"
                            type="text"
                            value={formData.OrderNumber}
                            onChange={handleChange}
                            label="Order Number"
                            variant="standard"
                            required
                        />
                    </FormControl>
                    <FormControl sx={{ m: 1, minWidth: 500 }} variant="standard">
                        <InputLabel shrink htmlFor="ship-date">
                            Ship Date
                        </InputLabel>
                        <Input
                            id="ship-date"
                            name="ShipDate"
                            type="date"
                            value={formData.ShipDate}
                            onChange={handleChange}
                            notched={false} // This prop is not available in Input, only shown for illustration
                        />
                        <FormHelperText>Enter the shipping date</FormHelperText>
                    </FormControl>
                    <FormControl sx={{m: 1, minWidth: 500}}>
                        <TextField
                            id="unit_price"
                            name="unit_price"
                            type="text"
                            value={formData.unit_price}
                            onChange={handleChange}
                            label="Wholesale Cost"
                            variant="standard"
                            required
                        />
                    </FormControl>
                    <FormControl sx={{m: 1, minWidth: 500}}>
                        <TextField
                            id="shipping_charges"
                            name="shipping_charges"
                            type="text"
                            value={formData.shipping_charges}
                            onChange={handleChange}
                            label="Shipping charges"
                            variant="standard"
                            required
                        />
                    </FormControl>
                    <FormControl sx={{m: 1, minWidth: 500}}>
                        <TextField
                            id="TotalClaimAmount"
                            name="TotalClaimAmount"
                            type="text"
                            value={formData.TotalClaimAmount}
                            onChange={handleChange}
                            label="Total Claim Amount"
                            variant="standard"
                            required
                        />
                    </FormControl>
                    <FormControl variant="standard" sx={{m: 1, minWidth: 500}}>
                        <InputLabel id="LabelClaimFor">Claim For</InputLabel>
                        <Select
                            labelId="LabelClaimFor"
                            id="claim_for"
                            name="claim_for"
                            value={formData.claim_for}
                            onChange={handleChange}
                            label="Claim For"
                            required
                        >
                            <MenuItem value={"Misdelivery"}>Misdelivery</MenuItem>
                            <MenuItem value={"Shipment Not Received"}>Shipment Not Received</MenuItem>
                            <MenuItem value={"Shipment Missing Contents"}>Shipment Missing Contents</MenuItem>
                            <MenuItem value={"Shipment Damaged"}>Shipment Damaged</MenuItem>
                            <MenuItem value={"COD Payment Not Collected"}>COD Payment Not Collected</MenuItem>
                        </Select>
                    </FormControl>
                </>
            )
        }
    };

    return (
        <span>
            <Button sx={{textTransform: 'none'}} startIcon={<AdsClickIcon/>} onClick={openDialog}>新建索赔</Button>
            <Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
                <DialogTitle>新建索赔</DialogTitle>
                <DialogContent>
                    <Box component="form" onSubmit={handleSubmit} sx={{display: 'flex', flexWrap: 'wrap'}}>
                        <FormControl sx={{m: 1, minWidth: 500}}>
                            <TextField
                                id="UID"
                                name="UID"
                                type="text"
                                value={formData.UID}
                                onChange={handleChange}
                                label="UID"
                                variant="standard"
                                required
                                disabled={!user.is_superuser}
                            />
                        </FormControl>
                        <FormControl variant="standard" sx={{m: 1, minWidth: 500}}>
                            <InputLabel id="Labelsupplier">Supplier</InputLabel>
                            <Select
                                labelId="Labelsupplier"
                                id="supplier"
                                name="supplier"
                                value={formData.supplier}
                                onChange={handleChange}
                                label="supplier"
                                required
                            >
                              <MenuItem value={"Fedex"} selected>Fedex</MenuItem>
                              <MenuItem value={"SMP"}>SMP</MenuItem>
                              <MenuItem value={"UPS"}>UPS</MenuItem>
                              <MenuItem value={"USPS"}>USPS</MenuItem>
                              <MenuItem value={"Other"}>Other</MenuItem>
                            </Select>
                        </FormControl>
                        {formData.supplier ? renderFormFields() : ""}
                        <MultiFileUpload
                            files={files}
                            setFiles={setFiles}
                            accept={"image/png, image/jpeg, application/pdf"}
                        />
                        <DialogActions>
                            <Button fullWidth variant="contained" sx={{mt: 3, mb: 2, ml: 45}}
                                    onClick={handleClose}>取消</Button>
                            <LoadingButton loading={loading} fullWidth type='submit'
                                           variant="contained" sx={{mt: 3, mb: 2}}>确认</LoadingButton>
                        </DialogActions>
                    </Box>
                </DialogContent>
            </Dialog>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={() => setSnackbar({...snackbar, open: false})}
                anchorOrigin={{vertical: 'top', horizontal: 'right'}}
            >
                <Alert onClose={() => setSnackbar({...snackbar, open: false})} severity={snackbar.severity}
                       sx={{width: '100%'}}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </span>
    );
}

export default CreateCustomerClaim;