import * as React from 'react';
import {connect} from "react-redux";
import store from "../../redux/store";
import {OFFER_SHEET_CLOSE, OFFER_SHEET_CLOSE_ALERT, OFFER_SHEET_OPEN,} from "../../redux/type";
import Paper from "@mui/material/Paper";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import Grid from "@mui/material/Grid";
import '../../css/App.css'
import ListAltIcon from "@mui/icons-material/ListAlt";
import Modal from "@mui/material/Modal";
import {
    OfferSheetLoad,
    UpdateSurcharge,
    CellEditOfferSheet,
    OfferSheetDel,
    OfferSheetADD
} from "../../redux/actions/RaterAction";
import LinearProgress from "@mui/material/LinearProgress";
import CustomNoRowsOverlay from "../../components/CustomNoRowsOverlay";
import {DataGridPro, GridColumnOrderChangeParams} from "@mui/x-data-grid-pro";
import SetColumns from "../../components/SetColumns";
import SetVisibility from "../../components/SetVisibility";
import {GridColDef} from "@mui/x-data-grid/models/colDef/gridColDef";
import {GridActionsCellItem} from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import EditFormDialog from "./EditDialog"
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {DialogContentText} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import LoadingButton from "@mui/lab/LoadingButton";
import Dialog from "@mui/material/Dialog";

const typographySx = {
    marginBottom: '0.3rem'
};
const inputSx = {
    mt: '0.3rem'
};

function surcharge_format(params) {
    console.log(params)
    return params;
}
var maps = {
    FEDEX: {
        'AHSDimensions': 'Additional handling surcharge - dimension',
        'AHSWeight': 'Additional handling surcharge - weight',
        'AHSPackaging': 'AHSPackaging',
        'OversizeCharge': ['FedEx Ground Oversize III', 'Oversize 3 (OS3)'],
        'DeliveryAreaSurcharge': {
            'Commercial': ['Delivery Area Surcharge Commercial', 'FedEx Ground Delivery Area', 'Delivery Area Surcharge Remote Commercial'],
            'Residential': ['Delivery Area Surcharge Residential']
        },  // 偏远
        'ExtendDeliveryAreaSurcharge': {
            'Commercial': ['Delivery Area Surcharge Extended Commercial', 'Delivery Area Surcharge Remote Commercial'],
            'Residential': ['Delivery Area Surcharge Remote Residential', 'Delivery Area Surcharge Extended Residential'],
        },  // 超偏远
        'DAS': {
            FEDEX_GROUND: ['Delivery Area Surcharge Alaska Commercial', 'Delivery Area Surcharge Hawaii Commercial', 'Delivery Area Surcharge Remote Commercial'],
            GROUND_HOME_DELIVERY: ['Delivery Area Surcharge Alaska Residential', 'Delivery Area Surcharge Hawaii Residential', 'Delivery Area Surcharge Remote Residential'],
        },
        'PeakAHSCharge': 'Peak - Additional Handling Surcharge',
        'PeakOversizeCharge': 'Peak - Oversize Charge',
        'AddressCorrection': 'AddressCorrection',
        'DeclaredValue': 'Insured value',
        'DirectSignature': 'Direct signature required',
        'ResidentialDeliveryCharge': ['Residential Surcharge', 'Residential delivery surcharge'],
        'UnauthorizedPackageCharge': 'Unauthorized Package Charge',
        'DangerousGoods': 'DangerousGoods',
        'SMART_POST': {
            'Non-Mach Surcharge': 'SmartPost Non-Machinable',
            'PeakSurcharge': 'Peak Surcharge',
            'DeliveryAndReturns': 'Delivery and Returns',
            // 'ResidentialDeliveryCharge': ['Residential delivery', 'Residential delivery surcharge'],
            'DeliveryAreaSurcharge': {
                'Commercial': ['Delivery Area Surcharge Commercial', 'Delivery Area Surcharge'],
                'Residential': ['Delivery Area Surcharge Residential']
            },
            'ExtendDeliveryAreaSurcharge': {
                'Commercial': ['Delivery Area Surcharge Extended Commercial'],
                'Residential': ['Delivery Area Surcharge Remote Residential', 'Delivery Area Surcharge Extended Residential'],
            },  // 超偏远

        }
    }, UPS: {
        'AHSDimensions': 'ADDITIONAL HANDLING',
        'AHSWeight': 'ADDITIONAL HANDLING(Weight)',
        'AHSPackaging': 'AHSPackaging',
        'OversizeCharge': 'LARGE PACKAGE(Residential)',
        'DeliveryAreaSurcharge': ['DELIVERY AREA(Rural Extended)', 'DELIVERY AREA(Rural Extended)'],
        'ExtendDeliveryAreaSurcharge': ['Delivery Area Surcharge Extended Commercial', 'DELIVERY AREA(Rural)'],
        'DAS': ['DELIVERY AREA(Alaska Extended)', 'Delivery Area Surcharge Hawaii Commercial', null],
        'PeakAHSCharge': 'PEAK SEASON SURCHARGE – ADDITIONAL HANDLING',
        'PeakOversizeCharge': 'PEAK SEASON SURCHARGE – LARGE PACKAGE',
        'AddressCorrection': 'AddressCorrection',
        'DeclaredValue': 'INSURANCE(EVS)',
        'DirectSignature': 'DELIVERY CONFIRMATION(Signature and Date Required)',
        'ResidentialDeliveryCharge': ['Residential Surcharge', 'Residential delivery surcharge'],
        'UnauthorizedPackageCharge': 'Unauthorized Package Charge',
        'DangerousGoods': 'DangerousGoods',
        'Non-Mach Surcharge': 'SmartPost Non-Machinable',
        'PeakSurcharge': 'Peak Surcharge',
        'DeliveryAndReturns': 'Delivery and Returns',
    }
}
const localStorageKey = 'OfferSheetModalColumns';
const localStorageVisibilityKey = 'OfferSheetVisibilityModalColumns';
const columnVisibilityModel = { // 控制是否展示列
    id: false,
}
class OfferSheet extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: this.props.offer_sheet_open,
            itemId: props['itemId'],
            data_list: '',
            password_display: false,
            PFN: '',
            logisticService: '',
            columns: null,
            Visibility: this.setVisibility,
            add_page_open: false,
            del_surcharge: '',
            openDeleteDialog: false
        }
    }
    closeAlert = () => store.dispatch({type: OFFER_SHEET_CLOSE_ALERT})

    ChangeColumnVisibilityModel = (modal) => {
        localStorage.setItem(localStorageVisibilityKey, JSON.stringify(modal))
        this.setState({Visibility: modal})
    }
    columns: GridColDef[] = this.getColumnsForService(this.props.search.service_name)
    set_columns = SetColumns(localStorageKey, this.columns);
    setVisibility = SetVisibility(localStorageVisibilityKey, columnVisibilityModel);
    ColumnOrderChange = (e: GridColumnOrderChangeParams) => {
        // 获取columns
        // console.log(set_columns)
        let old = this.set_columns[e.oldIndex]
        let target = this.set_columns[e.targetIndex]
        this.set_columns[e.targetIndex] = old
        this.set_columns[e.oldIndex] = target
        localStorage.setItem(localStorageKey, JSON.stringify(this.set_columns))
        // oldIndex > targetIndex
        // targetIndex < oldIndex
    }
    handleOpenDeleteDialog = (surcharge, serviceName) => {
        this.setState({...this.state, del_surcharge: surcharge, openDeleteDialog: true})
    }
    handleDelete = () => {
        this.props.OfferSheetDel({description: this.state.del_surcharge, service_name: this.props.search.service_name, S_number: this.props.search.PFN})
    }
    handleAddOfferSheet = (data) => {
        data["service_name"] = this.props.search.service_name
        data["S_number"] = this.props.search.PFN
        this.props.OfferSheetADD(data)
    }
    getColumnsForService(serviceName) {
        let baseColumns = [
            {id: 'id', field: "id", headerName: "ID", hideable: true},
            {id: 'surcharge', field: "surcharge", headerName: "附加费名", flex: 18},
            // {id: 'date', field: "date", headerName: "生效时间", flex: 3},
            {id: 'zone1', field: "1", headerName: "zone1", flex: 5, editable: true},
            {id: 'zone2', field: "2", headerName: "zone2", flex: 5, editable: true},
            {id: 'zone3', field: "3", headerName: "zone3", flex: 5, editable: true},
            {id: 'zone4', field: "4", headerName: "zone4", flex: 5, editable: true},
            {id: 'zone5', field: "5", headerName: "zone5", flex: 5, editable: true},
            {id: 'zone6', field: "6", headerName: "zone6", flex: 5, editable: true},
            {id: 'zone7', field: "7", headerName: "zone7", flex: 5, editable: true},
            {id: 'zone8', field: "8", headerName: "zone8", flex: 5, editable: true},
            {id: 'zone9', field: "9", headerName: "zone9", flex: 5, editable: true}
        ];
        if (["FEDEX_MWT","UPS_HWT"].includes(serviceName)) {
            baseColumns.push({id: 'max', field: "max", headerName: "封顶", flex: 5, editable: true})
        }
        baseColumns.push({id: 12, type: 'actions', field: 'actions', headerName: "Actions", getActions: (params) => {
                return [
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        title={"Delete"}
                        onClick={() => this.handleOpenDeleteDialog(params.row.surcharge, serviceName)}
                        key={`delete-${params.id}`}
                        color="secondary"
                    />
                ];
            }})
        return [...baseColumns];
    }

    componentDidMount() {
        this.props.OfferSheetLoad(
            this.props.page,
            this.props.pageSize,
            null,
            null,
            store.getState().RaterReducer.offer_sheet_search
        );
    }

    componentDidUpdate(prevProps) {
        if (this.props.data !== prevProps.data) {
            // this.Change(this.props.data, this.props.search);
            this.setState({logisticService: this.props.search.service_name, PFN: this.props.search.PFN})
        }
    }

    Close = () => {
        store.dispatch({type: OFFER_SHEET_CLOSE})
        this.setState({open: !this.state.open})
    }
    Open = () => {
        this.props.OfferSheetLoad(this.props.page, this.props.pageSize, null, null, store.getState().RaterReducer.offer_sheet_search)
        // this.Change(this.props.data)
        store.dispatch({type: OFFER_SHEET_OPEN})
        this.setState({open: !this.state.open})
    }
    AddOpen =() => {
        this.setState({...this.state, add_page_open: !this.state.add_page_open})
    }

    render() {
        return (
            <>
                <Button startIcon={<ListAltIcon/>} onClick={() => {
                    this.Open()
                }}>附加费
                </Button>
                <Modal
                    open={this.state.open}
                    onClose={() => {
                        this.Close()
                    }}
                    aria-labelledby="shipping-fee-title"
                    aria-describedby="shipping-fee-description"
                >
                    <Paper
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '80%',
                            maxHeight: '90vh',
                            padding: 2,
                            // overflow: 'auto' // 这里添加滚动
                        }}
                    >

                    <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <Typography variant="h6">{this.state.PFN}-{this.state.logisticService}</Typography>
                        <IconButton
                            sx={{position: 'relative'}}
                            onClick={() => this.Close()}
                        >
                            <CloseIcon/>
                        </IconButton>
                    </Box>

                        {/* content */}
                        <Grid container item md={12} xs={12} lg={12} spacing={1}>
                            <DataGridPro
                                rows={this.props.data}
                                rowHeight={50}
                                sx={{
                                    minHeight: '80vh', // 最小高度
                                    maxHeight: '80vh', // 最大高度，根据需要调整
                                    overflow: 'auto', // 开启内部滚动
                                    '& .MuiDataGrid-cell': {
                                        border: '1px solid rgba(0, 0, 0, 0.1)'
                                    },
                                    '& .MuiDataGrid-columnHeaders': {
                                        backgroundColor: '#92d050', // 设置您喜欢的颜色
                                    }
                                }}
                                columns={this.set_columns}
                                hideFooter={true}
                                loading={this.props.TableLoading}
                                rowCount={this.props.rowCount}
                                paginationMode='server'
                                columnVisibilityModel={this.state.Visibility}
                                onColumnVisibilityModelChange={(newModel) =>
                                    this.ChangeColumnVisibilityModel(newModel)
                                }
                                rowReordering={false}
                                onColumnOrderChange={(e) => {
                                    this.ColumnOrderChange(e)
                                }}
                                components={{
                                    LoadingOverlay: LinearProgress,
                                    NoRowsOverlay: CustomNoRowsOverlay,
                                }}
                                onCellEditCommit={params => {
                                    this.props.CellEditOfferSheet(params, this.state.logisticService, this.state.PFN)
                                }}
                                editMode={'cell'}
                                pagination={true}
                                disableColumnMenu
                                disableColumnResize
                                sortModel={[]}
                            />
                        </Grid>
                        <Button variant="contained" sx={{width: 100, mt: 0.5, mb: 0.5, float: "right"}} onClick={() => this.setState({...this.state, add_page_open: true})}>新增</Button>
                        <EditFormDialog open={this.state.add_page_open} setOpen={this.AddOpen} onSave={this.handleAddOfferSheet}></EditFormDialog>
                    </Paper>
                </Modal>
                <Dialog open={this.state.openDeleteDialog} onClose={() => this.setState({...this.state, openDeleteDialog: false})}>
                    <DialogTitle>确认删除</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            确定要删除{this.state.del_surcharge}吗？
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.setState({...this.state, openDeleteDialog: false})}>否</Button>
                        <LoadingButton
                            loading={this.props.offerSheetpageLoading}
                            variant={"contained"}
                            onClick={this.handleDelete}
                        >是</LoadingButton>
                    </DialogActions>
                </Dialog>
            </>)
    }
}

// 更新就渲染
const mapStateToProps = (state) => ({
    // isLoading: state.logInReducer.isLoading,
    pageLoading: state.RaterReducer.pageLoading,
    alert: state.RaterReducer.alert,
    isLoading: state.RaterReducer.offerSheetisLoading,
    TableLoading: state.RaterReducer.offerSheetTableLoading,
    pageSize: state.RaterReducer.offer_sheet_pageSize,
    page: state.RaterReducer.offer_sheet_page,
    rowCount: state.RaterReducer.offer_sheet_rowCount,
    data: state.RaterReducer.offer_sheet_list,
    search: state.RaterReducer.offer_sheet_search,
    offer_sheet_open: state.RaterReducer.offer_sheet_open,
    payload: state.RaterReducer.offer_sheet_content,
    user: state.auth.user,
    offerSheetpageLoading: state.RaterReducer.offerSheetpageLoading
});

export default connect(mapStateToProps, {OfferSheetLoad, UpdateSurcharge, CellEditOfferSheet, OfferSheetDel, OfferSheetADD})(OfferSheet);
