import * as React from 'react';
import {connect} from "react-redux";
import Button from '@mui/material/Button';
import './modalStyles.scss'
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import {DataGridPro} from "@mui/x-data-grid-pro";
import LinearProgress from "@mui/material/LinearProgress";
import CustomNoRowsOverlay from "../../components/CustomNoRowsOverlay";
import APIToolBar from "../../components/APIToolBar";
import {LoadSecondaryTrackingNumber} from "../../redux/actions/modal";
import {GridColDef} from "@mui/x-data-grid/models/colDef/gridColDef";
import SetColumns from "../../components/SetColumns";
import SetVisibility from "../../components/SetVisibility";
import store from "../../redux/store";
import Dialog from "@mui/material/Dialog";

function track_history_format(e){
    if (e.value === "{}") {
        return ""
    }else {
        return e.value
    }
}

let columns: GridColDef[] = [
    {id: 0, field: "id", headerName: "Id"},
    {id: 1, field: "TrackingNumber", headerName: "tracking number", flex: 15},
    {id: 2, field: "Length", headerName: "Length", flex: 5},
    {id: 3, field: "Width", headerName: "Width", flex: 5},
    {id: 4, field: "Height", headerName: "Height", flex: 5},
    {id: 5, field: "Weight", headerName: "Weight", flex: 5},
    {id: 6, field: "GoodsName", headerName: "GoodsName", flex: 8},
    {id: 7, field: "Insure_value", headerName: "Insure value", flex: 8},
    {id: 8, field: "length_units", headerName: "length units", flex: 8},
    {id: 9, field: "DeliveryConfirmationType", headerName: "DeliveryConfirmationType", flex: 10},
    {id: 10, field: "Zone", headerName: "Zone", flex: 5},
    {id: 11, field: "CLASS", headerName: "CLASS", flex: 5},
    {id: 12, field: "track_code", headerName: "last trace code", flex: 10},
    {id: 13, field: "track_description", headerName: "last trace desc", flex: 10},
    {id: 14, field: "track_history", headerName: "history trace", flex: 10, valueFormatter: track_history_format},
]
const columnVisibilityModel = { // 控制是否展示列
    id: false,
    DeliveryConfirmationType: false,
}
const localStorageKey = 'PackageDisplayModalColumns';
const localStorageVisibilityKey = 'PackageDisplayVisibilityModalColumns';
const set_columns = SetColumns(localStorageKey, columns);
const setVisibility = SetVisibility(localStorageVisibilityKey, columnVisibilityModel);

class PackageDisplay extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            Visibility: setVisibility
        }
    }
    Close = () => {
        this.setState({open: false})
    }

    Open = () => {
        this.props.LoadSecondaryTrackingNumber(this.props.page, this.props.pageSize, null, null, this.props.search, this.props.trace_id)
        this.setState({open: true})
    }
    useQuery = (page) => {
        this.props.LoadSecondaryTrackingNumber((page * this.props.pageSize), ((page + 1) * this.props.pageSize), page,
            null, store.getState().shipment.search, this.props.trace_id)
    }
    changePageSize = (pageSize) => {
        setTimeout(() => {
            this.props.LoadSecondaryTrackingNumber((this.props.page * pageSize), ((this.props.page + 1) * pageSize),
                null, pageSize, store.getState().shipment.search, this.props.trace_id)
        }, 100);
    }

    ChangeColumnVisibilityModel = (modal) => {
        localStorage.setItem(this.localStorageVisibilityKey, JSON.stringify(modal))
        this.setState({Visibility: modal})
    }

    render() {
        return (
            <div>
                <Button onClick={this.Open}>{this.props.trace}</Button>
                <Dialog
                    fullWidth={true}
                    maxWidth={'xl'}
                    disableEscapeKeyDown
                    open={this.state.open}
                    onClose={() => {this.Close()}}>

                    <Container>
                        <Grid container spacing={3} sx={{height: '85vh'}}>
                            <Grid item xs={12} md={12} lg={12}>
                                    <DataGridPro
                                        rows={this.props.data}
                                        sx={{overflow: 'auto', display: 'block', minWidth: "100%"}}
                                        columns={set_columns}
                                        pageSize={this.props.pageSize}
                                        rowsPerPageOptions={[20, 30, 50, 100, 200, 500]}
                                        autoHeight={true}
                                        loading={this.props.isLoading}
                                        rowCount={this.props.rowCount}
                                        paginationMode='server'
                                        columnVisibilityModel={this.state.Visibility}
                                        onColumnVisibilityModelChange={(newModel) =>
                                            this.ChangeColumnVisibilityModel(newModel)
                                        }
                                        rowReordering={false}

                                        onColumnOrderChange={(e) => {
                                            this.ColumnOrderChange(e)
                                        }}
                                        components={{
                                            LoadingOverlay: LinearProgress,
                                            NoRowsOverlay: CustomNoRowsOverlay,
                                            Toolbar: APIToolBar
                                        }}
                                        onPageChange={(page) => this.useQuery(page)}
                                        onPageSizeChange={pageSize => this.changePageSize(pageSize)}
                                        pagination={true}
                                        onCellClick={(row, e) => e.stopPropagation()}
                                    />
                            </Grid>
                        </Grid>
                    </Container>
                </Dialog>
            </div>
        );
    }
}

// 更新就渲染
const mapStateToProps = (state) => ({
    isLoading: state.modal.isLoading,
    pageSize: state.modal.pageSize,
    page: state.modal.page,
    rowCount: state.modal.rowCount,
    data: state.modal.data,
    user: state.auth.user,
    search: state.modal.search
});

export default connect(mapStateToProps, {LoadSecondaryTrackingNumber})(PackageDisplay);